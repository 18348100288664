<template>
  <b-offcanvas
    v-model="opened"
    title=""
    placement="top"
    class="offcanvas-contest-payment-request"
    v-if="paymentRequest"
  >
    <div class="mb-4 text-center text-uppercase">
      <h6> {{ paymentRequest.title }} </h6>
    </div>
    <div class="text-center">
      <img :src="qrcodeURL" />
    </div>
    <div class="mt-3">
      <div class="input-group">
        <input
          readonly
          :value="paymentRequest.pix_code"
          type="text"
          class="form-control pixcode-input"
        >
        <button
          class="btn btn-dark"
          type="button"
          @click="copyCode"
        >
          <i class="far fa-copy"></i> Copiar
        </button>
      </div>
    </div>
    <div class="mt-3">
      <h4 class="offcanvas-title display-5 text-center" id="offcanvas-contest-payment-request-label" style="color: #65DA70; text-align: center; width: 100%;">
        {{  $n(paymentRequest.amount, 'currency') }}
      </h4>
    </div>
    <div class="text-center mt-3">
      <h5 style="line-height: 2rem;">
        Efetue o pagamento do valor acima
        para liberar a publicação de seu sorteio
      </h5>
    </div>
  </b-offcanvas>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import storage from '@/storage'
import { Toasts } from '../utils/toast-alerts'

export default {
  name: 'ContestPaymentRequest',
  setup() {
    const store = useStore()
    const whatsapp = ref(null)
    const features = ref([])
    const googleURL = 'https://chart.googleapis.com/chart?chs=300x300&chld=Q|1&cht=qr&chl='
    const user = storage.getItem('user')

    const paymentRequest = computed(() => store.state.app.contestPaymentRequest)

    if (user) {
      window.Echo.channel(`private-users.${user.id}`)
        .listen('.payment.confirmed', () => {
          opened.value = false
          // bug: forçar remoção do backdrop da modal
          const backdrop = document.querySelector('.offcanvas-backdrop.fade.show')
          if (backdrop) {
            backdrop.parentNode.removeChild(backdrop)
          }
        })
    }

    const opened =  computed({
      get: () => paymentRequest.value !== null,
      set: (value) => {
        if (!value) {
          store.dispatch('app/setContestPaymentRequest', null)
        } else {
          // checkAccount().then((data) => {
          //   whatsapp.value = data.upgradeWhatsapp
          //   features.value = Object.values(data.premiumFeatures)
          // })
        }
      }
    })

    const qrcodeURL = computed(() => {
      if (paymentRequest.value) {
        return `${googleURL}${paymentRequest.value.pix_code}`
      }
      return null
    })

    const copyCode = () => {
      const input = document.querySelector('.pixcode-input')
      input.focus()
      input.select()
      const result = document.execCommand('copy')
      if (result) {
        Toasts('success', 'Código de pagamento copiado!')
      } else {
        Toasts('error', 'Falha ao copiar código de pagamento!')
      }
    }

    return {
      opened,
      whatsapp,
      features,
      qrcodeURL,
      copyCode,
      paymentRequest
    }
  }
}
</script>

<style>
.offcanvas-contest-payment-request {
  height: 100vh;
}
@media only screen and (min-width: 1366px) {
  .offcanvas-contest-payment-request {
    width: 30vw;
    left: 35vw;
  }
}
</style>
