<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";
import UpgradeScreen from "@/components/upgrade-screen"
import ContestPaymentRequest from "@/components/contest-payment-request"

export default {
  props: {
    pagetitle: {
      type: String,
      required: true,
    },
  },
  components: {
    Vertical,
    Horizontal,
    UpgradeScreen,
    ContestPaymentRequest
  },
  setup() {
    return {};
  },
  computed: {
    ...layoutComputed,
  },
};
</script>

<template>
  <UpgradeScreen />
  <ContestPaymentRequest />
  <div>
    <Vertical :pagetitle="pagetitle" v-if="layoutType === 'vertical'" :layout="layoutType">
      <slot />
    </Vertical>

    <Horizontal :pagetitle="pagetitle" v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>
  </div>
</template>
