<script>

import storage from '@/storage'

/**
 * Topbar component
 */
export default {
  data() {
    return {
      text: null,
      flag: null,
      value: null,
      myAccount: {}
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    layoutscroll: {
      type: Boolean,
      required: true,
    },
    pagetitle: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.myAccount = storage.getItem('user') ?? {}
  },
  methods: {
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    logout() {
      if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
        this.authLogout().then(() => {
          this.$router.push(
            this.$route.query.redirectFrom || {
              name: "login",
            }
          );
        });
      } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
        this.authFakeLogout().then(() => {
          this.$router.push(
            this.$route.query.redirectFrom || {
              name: "login",
            }
          );
        });
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
  },
  components: {
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-topbar", "dark");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    layoutscroll: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case false:
              document.body.setAttribute("data-layout-scrollable", "false");
              break;
            case true:
              document.body.setAttribute("data-layout-scrollable", "true");
              break;
            default:
              document.body.setAttribute("data-layout-scrollable", "false");
              break;
          }
        }
      },
    },
  },
};
</script>

<template>
  <header id="page-topbar" class="isvertical-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-dark-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark-sm.png" alt="" height="22" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt="" height="22" />
            </span>
            <span class="logo-sm">
              <img src="@/assets/images/logo-light-sm.png" alt="" height="22" />
            </span>
          </router-link>
        </div>

        <button
          type="button"
          class="
            btn btn-sm
            px-3
            font-size-16
            header-item
            vertical-menu-btn
            topnav-hamburger
          "
          @click="toggleMenu"
        >
          <span class="hamburger-icon open">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>

        <div class="d-none d-sm-block ms-3 align-self-center">
          <h4 class="page-title">{{ pagetitle }}</h4>
        </div>
      </div>

      <div class="d-flex">
        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item user text-start d-flex align-items-center"
          >
            <h4 class="page-title">{{ myAccount.name }}</h4>
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/logo-light-sm.png"
              alt="Header Avatar"
            />
          </button>
        </div>
      </div>
    </div>
  </header>
</template>
