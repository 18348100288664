<template>
  <b-offcanvas
    v-model="opened"
    title=""
    placement="bottom"
    class="offcanvas-upgrade"
  >
    <template v-slot:title>
      <h2 class="offcanvas-title display-5" id="offcanvas-upgrade-label" style="color: #65DA70; text-align: center; width: 100%;">
        Assine nosso plano
        <strong>PREMIUM!</strong>
      </h2>
    </template>

    <ul class="list-unstyled">
      <li v-for="(feature, i) in features" :key="i">
        <h5 class="mb-4">
          <i :class="feature.icon"></i>
          {{ feature.caption }}
        </h5>
      </li>
    </ul>
    <div>
      <a
        v-if="whatsapp"
        class="btn btn-success btn-lg mt-4"
        :href="whatsapp"
        target="_blank"
        role="button"
        style="width: 100%;"
      >
        <i class="fab fa-whatsapp"></i>
        Tornar-me <strong>PREMIUM</strong> sem limites!
      </a>
    </div>
  </b-offcanvas>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { checkAccount } from '@/feature-check'

export default {
  name: 'ModalUpgrade',
  setup() {
    const store = useStore()
    const whatsapp = ref(null)
    const features = ref([])

    const opened =  computed({
      get: () => store.state.app.showUpgradeScreen,
      set: (value) => {
        if (!value) {
          store.dispatch('app/hideUpgradeScreen')
        } else {
          checkAccount().then((data) => {
            whatsapp.value = data.upgradeWhatsapp
            features.value = Object.values(data.premiumFeatures)
          })
        }
      }
    })

    return {
      opened,
      whatsapp,
      features,
    }
  }
}
</script>

<style>
.offcanvas-upgrade {
  height: 90vh;
}
@media only screen and (min-width: 1366px) {
  .offcanvas-upgrade {
    width: 30vw;
    left: 35vw;
  }
}
</style>
